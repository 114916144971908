import {HelpOutline} from '@mui/icons-material';
import {styled, Tooltip, tooltipClasses, TooltipProps, Typography} from '@mui/material';
import {FC, ReactNode, useState} from 'react';
import {useIntl} from 'react-intl';
import {Span} from '../../styledComponents/Span';

const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => <Tooltip {...props} classes={{popper: className}} />)(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 900,
        backgroundColor: theme.palette.background.paper,
        color: 'rgba(0, 0, 0, 0.87)',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 5,
    },
}));

type Props = {
    readonly text?: string;
    readonly textRaw?: string;
    readonly value?: string | ReactNode | any;
    readonly placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    readonly color?: 'inherit' | 'disabled' | 'action' | 'primary' | 'secondary' | 'error';
    readonly fontSize?: 'inherit' | 'small' | 'large' | 'medium';
    readonly htmlText?: ReactNode;
    readonly withButton?: boolean;
    readonly children?: ReactNode;
    readonly showIcon?: boolean;
    readonly isInTable?: boolean;
    readonly open?: boolean;
};
export const TooltipIcon: FC<Props> = ({
    text,
    placement,
    color,
    fontSize,
    htmlText,
    children,
    value,
    withButton,
    textRaw,
    showIcon,
    isInTable,
    open = false,
}) => {
    const {formatMessage} = useIntl();
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const handleOpenNapoveda = () => setTooltipIsOpen(true);
    const handleCloseNapoveda = () => setTooltipIsOpen(false);
    return (
        <CustomWidthTooltip
            open={tooltipIsOpen || open}
            onOpen={handleOpenNapoveda}
            onClose={handleCloseNapoveda}
            placement={placement}
            title={
                htmlText || text ? (
                    <Typography variant="caption">{htmlText ? htmlText : formatMessage({id: text ?? 'empty'}, {value})} </Typography>
                ) : (
                    textRaw ?? ''
                )
            }
        >
            <Span sx={{display: 'flex', marginLeft: isInTable ? -1.1 : undefined}}>
                {children}
                {(htmlText || text || textRaw) && (children || showIcon) && (
                    <HelpOutline
                        sx={(theme) => ({
                            opacity: 0.65,
                            fontSize: '0.9rem',
                            marginLeft: withButton ? -1 : 1,
                            ...(open && {
                                borderRadius: '50%',
                                transform: 'scale(1)',
                                animation: 'pulse 2s infinite',
                                '@keyframes pulse': {
                                    '0%': {
                                        transform: 'scale(0.95)',
                                        boxShadow: `0 0 0 0 ${theme.palette.primary.main}`,
                                    },
                                    '70%': {
                                        transform: 'scale(1)',
                                        boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
                                    },
                                    '100%': {
                                        transform: 'scale(0.95)',
                                        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                                    },
                                },
                            }),
                        })}
                        color={color}
                        fontSize={fontSize}
                        onClick={handleOpenNapoveda}
                    />
                )}
            </Span>
        </CustomWidthTooltip>
    );
};
