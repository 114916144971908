import {Maybe, PppAkumulacniModul, PppTechnickaSpecifikace, PppZadatelTyp, PppZakaznik, PppZakladniUdaje} from '@eon.cz/apollo13-graphql-web';
import {PrilohaBase} from './components/files/utils/PrilohyUtils';

export const Zahranici = {
    ANO: 'ANO',
    NE: 'NE',
} as const;

export type Zahranici = (typeof Zahranici)[keyof typeof Zahranici];

export type PppZakladniUdajeForm = PppZakladniUdaje & {
    readonly obec: string;
};

export type ZmenaTechnickychParametruVyrobny = 'ANO' | 'NE';
export type PppVyrizujici = {
    readonly nazevFirmy: string | undefined;
    readonly jmeno: string | undefined;
    readonly prijmeni: string | undefined;
    readonly telefon: string;
    readonly email: string;
    readonly typVyrizujici: PppZadatelTyp;
};

export type PppZakaznikForm = Omit<PppZakaznik, 'vyrizujiciFO' | 'vyrizujiciPO'> & {
    readonly typZadatele: PppZadatelTyp;
    readonly zahranici: Zahranici;
    readonly vyrizujici: PppVyrizujici;
};

export type PppTechnickaSpecifikaceForm = PppTechnickaSpecifikace & {
    readonly typZdroje: string;
    readonly potvrzeniOVystavbe: boolean;
    readonly RZElektromerovehoRozvadeceSoucastiRZ: boolean;
    readonly elektromerovyRozvadecJePripraven: boolean;
    readonly potvrzeniONastaveniSO: boolean;
    readonly prilohyZRQ17: PrilohaBase[][];
    readonly prilohyZPD: PrilohaBase[];
    readonly prilohyZRQ16: PrilohaBase[];
    readonly prilohyZRQ15: PrilohaBase[];
    readonly prilohyZRQ18: PrilohaBase[];
    readonly prilohyZRQ43: PrilohaBase[];
    readonly prilohyRZ: PrilohaBase[];
    readonly prilohyZRQ14: PrilohaBase[];
    readonly prilohyZ1PSCHEMA: PrilohaBase[];
    readonly zmenaTechnickychParametruVyrobny: ZmenaTechnickychParametruVyrobny;
    readonly pocetStridacu: boolean;
    readonly celkovaKapacitaAkumulace?: number;
    readonly error?: boolean;
    readonly akumulacniModul: PppAkumulacniModul & {typVyrobny?: number};
};

export type PppCreateInputForm = {
    readonly zakladniUdaje: PppZakladniUdajeForm;
    readonly typZakaznika: PppZadatelTyp;
    readonly zakaznik: PppZakaznikForm;
    readonly technickaSpecifikace: PppTechnickaSpecifikaceForm;
    readonly cisloZadosti: string | undefined;
    readonly createMessage: Maybe<string> | undefined;
};
